import AppLayoutSubheaderWrapper from "~/components/layouts/AppLayout/AppLayoutSubheaderWrapper";

import IndexDescription from "./IndexDescription";
import IndexMarketingCampaigns from "./IndexMarketingCampaigns";

const Index = (): JSX.Element => (
  <AppLayoutSubheaderWrapper>
    <IndexDescription />
    <IndexMarketingCampaigns />
  </AppLayoutSubheaderWrapper>
);

export default Index;
