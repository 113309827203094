import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Trans from "next-translate/Trans";

import typography from "~/theme/typography";

type Props = {
  translationKeyWithNamespace: string;
  className?: string;
};

const NftPageSlogan = ({
  translationKeyWithNamespace,
  className
}: Props): JSX.Element => (
  <Slogan className={className}>
    <Trans
      i18nKey={translationKeyWithNamespace}
      components={{
        Highlighted: <SloganHighlighted />
      }}
    />
  </Slogan>
);

export default NftPageSlogan;

const Slogan = styled(Typography)`
  display: block;
  margin: 0 auto;
  text-align: center;

  ${typography.h4};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    ${typography.h3};
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    ${typography.h2};
  }
`;
Slogan.defaultProps = {
  color: "text.primary"
};

const SloganHighlighted = styled.span`
  color: ${({ theme }) => theme.palette.brandPurple.main};
`;
