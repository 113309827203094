import styled from "@emotion/styled";

import IndexMarketingGoogleCloudCampaign from "~/components/advertisement/campaigns/Index/IndexMarketingCampaigns/campaigns/IndexMarketingGoogleCloudCampaign";
import IndexMarketingHpCampaign from "~/components/advertisement/campaigns/Index/IndexMarketingCampaigns/campaigns/IndexMarketingHpCampaign";
import IndexMarketingLenovoCampaign from "~/components/advertisement/campaigns/Index/IndexMarketingCampaigns/campaigns/IndexMarketingLenovoCampaign";
import { appLayoutPaddingsMixin } from "~/components/layouts/AppLayout/styled";

import IndexMarketingCleoCampaign from "./campaigns/IndexMarketingCleoCampaign";
import IndexMarketingIliane from "./campaigns/IndexMarketingIliane";

const IndexMarketingCampaigns = (): JSX.Element => (
  <Background>
    <Wrapper>
      <IndexMarketingCleoCampaign />
      <IndexMarketingHpCampaign />
      <IndexMarketingLenovoCampaign />
      <IndexMarketingGoogleCloudCampaign />
      <IndexMarketingIliane />
    </Wrapper>
  </Background>
);

export default IndexMarketingCampaigns;

const Background = styled.div`
  background: ${({ theme }) => theme.palette.background.octonary};
`;

const Wrapper = styled.section`
  ${appLayoutPaddingsMixin}

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};

  padding-bottom: ${({ theme }) => theme.spacing(5)};
  padding-top: ${({ theme }) => theme.spacing(10)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-bottom: ${({ theme }) => theme.spacing(18)};
    padding-top: ${({ theme }) => theme.spacing(6)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: ${({ theme }) => theme.spacing(5)};
    padding-top: ${({ theme }) => theme.spacing(10)};
  }
`;
