import useTranslation from "next-translate/useTranslation";

import Image from "~/public/assets/images/campaigns/Hp/Background.jpg";
import Logo from "~/public/assets/images/campaigns/Hp/Logo.svg";

import IndexMarketingCampaignInviteOnlyBadge from "./common/badges/IndexMarketingCampaignInviteOnlyBadge";
import IndexMarketingCampaign from "./common/IndexMarketingCampaign";

const IndexMarketingHpCampaign = (): JSX.Element => {
  const { t } = useTranslation("index");

  return (
    <IndexMarketingCampaign
      type={t("hp_type")}
      title={t("hp_title")}
      description={t("hp_description")}
      background="brandGreen.lightMax"
      image={Image.src}
      logo={<Logo />}
      logoBackground="brandGreen.lightMax"
      topStatisticsItem={{
        icon: "goblet",
        background: "brandGreen.dark",
        label: t("individual-reward_label"),
        value: t("hp_individual-reward_title")
      }}
      bottomStatisticsItem={{
        icon: "reward",
        background: "brandGreen.dark",
        label: t("total-reward_label"),
        value: t("hp_total-reward_title")
      }}
      pictureOnRight={false}
    >
      <IndexMarketingCampaignInviteOnlyBadge />
    </IndexMarketingCampaign>
  );
};

export default IndexMarketingHpCampaign;
