import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import NoSsr from "@mui/material/NoSsr";
import Typography, { TypographyProps } from "@mui/material/Typography";
import NextLink from "next/link";
import useTranslation from "next-translate/useTranslation";

import ClaimButton from "~/components/advertisement/claim/ClaimButton";
import useCheckAttendanceBeforeClaim from "~/components/advertisement/claim/useCheckAttendanceBeforeClaim";
import Logo from "~/components/common/Logo";
import { Vendor } from "~/components/landings/constants";
import { VENDOR_TO_ADVERTISEMENT_MAP } from "~/components/landings/vendorToAdvertisementMap";
import ROUTES from "~/constants/routes";
import { StyledMixin } from "~/declarations/styled";
import Image from "~/public/assets/images/campaigns/Cleo/Background.jpg";
import { safeDynamicImport } from "~/utils/import/dynamic";

import IndexMarketingCampaign from "./common/IndexMarketingCampaign";

const CheckAttendanceBeforeClaimDialog = safeDynamicImport(
  () =>
    import("~/components/advertisement/claim/CheckAttendanceBeforeClaimDialog"),
  {
    ssr: false
  }
);

const IndexMarketingCleoCampaign = (): JSX.Element => {
  const { t } = useTranslation("index");

  const advertisementId = VENDOR_TO_ADVERTISEMENT_MAP[Vendor.Cleo];
  const { claimAlert, hasNeedToClaimCheck } = useCheckAttendanceBeforeClaim();

  if (!advertisementId) {
    throw new Error("Add postId for Cleo campaign");
  }

  return (
    <>
      <IndexMarketingCampaign
        type={t("cleo_type")}
        title={t("cleo_title")}
        description={t("cleo_description")}
        background="brandPurple.lightMax"
        image={Image.src}
        logo={<Logo size="xl" withLink={false} />}
        logoBackground="brandPurple.lightMax"
        topStatisticsItem={{
          icon: "goblet",
          background: "brandPurple.dark",
          label: t("individual-reward_label"),
          value: <RewardValue>{t("cleo_individual-reward_title")}</RewardValue>
        }}
        bottomStatisticsItem={{
          icon: "reward",
          background: "brandPurple.dark",
          label: t("total-reward_label"),
          value: <RewardValue>{t("cleo_total-reward_title")}</RewardValue>
        }}
        pictureOnRight
      >
        <DetailsButton LinkComponent={NextLink} href={ROUTES.landing.Cleo}>
          {t("details_action")}
        </DetailsButton>
        <NoSsr>
          <StyledClaimButton
            color="brandPurple.main"
            advertisementId={advertisementId}
            claimActionLabel={t("claim_action")}
            hasNeedToClaim={hasNeedToClaimCheck}
          />
        </NoSsr>
      </IndexMarketingCampaign>
      {claimAlert.mounted && (
        <CheckAttendanceBeforeClaimDialog
          {...claimAlert.dialogProps}
          {...claimAlert.payload}
        />
      )}
    </>
  );
};

export default IndexMarketingCleoCampaign;

const marketingCampaignCardActionMixin: StyledMixin = ({ theme }) => css`
  min-width: 176px;

  ${theme.breakpoints.down("md")} {
    flex: 1;
    min-width: unset;
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  }
`;

const StyledClaimButton = styled(ClaimButton)`
  ${marketingCampaignCardActionMixin}

  ${({ theme }) => theme.breakpoints.down("xs")} {
    flex-basis: 100%;
  }
`;

const DetailsButton = styled(Button)`
  ${marketingCampaignCardActionMixin}

  min-width: 176px;
`;
DetailsButton.defaultProps = {
  variant: "contained",
  size: "large",
  color: "primary"
};

const RewardValue = styled(Typography)``;
RewardValue.defaultProps = {
  variant: "h6",
  component: "span"
} as TypographyProps;
