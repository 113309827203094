import { VendorToLandingComponentMap } from "~/components/landings/declarations";
import CleoLanding from "~/components/landings/vendors/CleoLanding";

export enum Vendor {
  Cleo = "Cleo"
}

export const VENDOR_TO_LANDING_COMPONENT: VendorToLandingComponentMap = {
  [Vendor.Cleo]: CleoLanding
};
