import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

const NftPageTitle = styled(Typography)`
  display: block;
`;
NftPageTitle.defaultProps = {
  variant: "titleSecondary",
  color: "brandGreen.main"
};

export default NftPageTitle;
