import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import useTranslation from "next-translate/useTranslation";

import NftPageDescriptionWrapper from "~/components/nfts/common/NftPageDescriptionWrapper";
import NftPageSlogan from "~/components/nfts/common/NftPageSlogan";
import NftPageTitle from "~/components/nfts/common/NftPageTitle";
import typography from "~/theme/typography";

const IndexDescription = (): JSX.Element => {
  const { t } = useTranslation("index");

  return (
    <NftPageDescriptionWrapper>
      <NftPageTitle>{t("title")}</NftPageTitle>
      <Slogan translationKeyWithNamespace="index:slogan" />
      <Description>{t("description")}</Description>
    </NftPageDescriptionWrapper>
  );
};

export default IndexDescription;

const Slogan = styled(NftPageSlogan)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 710px;
  }
`;

const Description = styled(Typography)`
  max-width: 500px;
  ${typography.paragraphTertiary}

  ${({ theme }) => theme.breakpoints.up("sm")} {
    ${typography.paragraphSecondary}
  }
`;
