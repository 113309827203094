import useTranslation from "next-translate/useTranslation";

import Image from "~/public/assets/images/campaigns/Iliane/Background.png";
import Logo from "~/public/assets/images/campaigns/Iliane/Logo.svg";

import {
  CompletedBadge,
  FullyClaimedBadge,
  OutlinedIndexMarketingCampaign
} from "./common/styled";

const IndexMarketingIliane = (): JSX.Element => {
  const { t } = useTranslation("index");

  return (
    <OutlinedIndexMarketingCampaign
      type={t("iliane_type")}
      title={t("iliane_title")}
      description={t("iliane_description")}
      background="background.octonary"
      image={Image.src}
      logo={<Logo />}
      logoBackground="background.septenary"
      topStatisticsItem={{
        icon: "goblet",
        background: "background.primary",
        label: t("individual-reward_label"),
        value: t("iliane_individual-reward_title")
      }}
      bottomStatisticsItem={{
        icon: "reward",
        background: "background.primary",
        label: t("total-reward_label"),
        value: t("iliane_total-reward_title")
      }}
      pictureOnRight
    >
      <CompletedBadge />
      <FullyClaimedBadge />
    </OutlinedIndexMarketingCampaign>
  );
};

export default IndexMarketingIliane;
