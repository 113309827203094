import useTranslation from "next-translate/useTranslation";

import IndexMarketingCampaignBadge from "./IndexMarketingCampaignBadge";

type Props = {
  className?: string;
};

const IndexMarketingCampaignFullyClaimedBadge = ({
  className
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <IndexMarketingCampaignBadge
      className={className}
      icon="reward"
      iconColor="brandGreen.main"
      label={t("advertisement_fully-claimed_status")}
    />
  );
};

export default IndexMarketingCampaignFullyClaimedBadge;
