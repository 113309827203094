
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Index from "~/components/advertisement/campaigns/Index/Index";
import Meta from "~/components/common/Meta/Meta";
const HomePage = (): JSX.Element => (<>
    <Meta pageKey="index"/>
    <Index />
  </>);
export default HomePage;

    async function __Next_Translate__getStaticProps__18c1550bffb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18c1550bffb__ as getStaticProps }
  