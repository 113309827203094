import styled from "@emotion/styled";

import { appLayoutPaddingsMixin } from "~/components/layouts/AppLayout/styled";

const NftPageDescriptionWrapper = styled.div`
  ${appLayoutPaddingsMixin};
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
  padding-bottom: ${({ theme }) => theme.spacing(20)};
  padding-top: ${({ theme }) => theme.spacing(20)};
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("xs")} {
    padding-bottom: ${({ theme }) => theme.spacing(20)};
    padding-top: ${({ theme }) => theme.spacing(22)};
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-bottom: ${({ theme }) => theme.spacing(25)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding-bottom: ${({ theme }) => theme.spacing(28)};
    padding-top: ${({ theme }) => theme.spacing(28)};
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding-bottom: ${({ theme }) => theme.spacing(40)};
    padding-top: ${({ theme }) => theme.spacing(30)};
  }
`;

export default NftPageDescriptionWrapper;
