import useTranslation from "next-translate/useTranslation";

import IndexMarketingCampaignBadge from "./IndexMarketingCampaignBadge";

type Props = {
  className?: string;
};

const IndexMarketingCampaignCompletedBadge = ({
  className
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <IndexMarketingCampaignBadge
      className={className}
      icon="check"
      iconColor="brandGreen.main"
      label={t("advertisement_complete_status")}
    />
  );
};

export default IndexMarketingCampaignCompletedBadge;
