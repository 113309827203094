import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import { IconVariant } from "~/components/core/Icon/declarations";
import IconAvatar from "~/components/core/IconAvatar";
import { ColorsFromPalette } from "~/theme/colorsFromPalette";
import typography from "~/theme/typography";

export type IndexMarketingCampaignStatisticsItemProps = {
  background: ColorsFromPalette;
  icon: IconVariant;
  label: ReactNode;
  value: ReactNode;
};

const IndexMarketingCampaignStatisticsItem = ({
  background,
  icon,
  label,
  value
}: IndexMarketingCampaignStatisticsItemProps): JSX.Element => (
  <Wrapper>
    <IconAvatar
      icon={icon}
      iconSize={32}
      size={56}
      iconColor="background.octonary"
      backgroundColor={background}
    />
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Wrapper>
);

export default IndexMarketingCampaignStatisticsItem;

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.transparent.inverse[4]};
  border-radius: 24px;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ theme }) => theme.spacing(5)};
  }
`;

const Label = styled(Typography)`
  display: block;
  margin-top: ${({ theme }) => theme.spacing(3.5)};
`;
Label.defaultProps = {
  variant: "titleTertiary",
  color: "text.secondary"
};

const Value = styled(Typography)`
  display: block;

  ${typography.h5}
  margin-top: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.up("xl")} {
    margin-top: ${({ theme }) => theme.spacing(2)};
    ${typography.h4}
  }
`;
