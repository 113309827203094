import styled from "@emotion/styled";

import IndexMarketingCampaignCompletedBadge from "./badges/IndexMarketingCampaignCompletedBadge";
import IndexMarketingCampaignFullyClaimedBadge from "./badges/IndexMarketingCampaignFullyClaimedBadge";
import IndexMarketingCampaign from "./IndexMarketingCampaign";

export const OutlinedIndexMarketingCampaign = styled(IndexMarketingCampaign)`
  border: 1px solid ${({ theme }) => theme.palette.background.senary};
`;

export const CompletedBadge = styled(IndexMarketingCampaignCompletedBadge)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex: 1;
  }
`;

export const FullyClaimedBadge = styled(
  IndexMarketingCampaignFullyClaimedBadge
)`
  ${({ theme }) => theme.breakpoints.down("md")} {
    flex: 1;
  }
`;
