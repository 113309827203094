import useTranslation from "next-translate/useTranslation";

import IndexMarketingCampaignBadge from "./IndexMarketingCampaignBadge";

const IndexMarketingCampaignInviteOnlyBadge = (): JSX.Element => {
  const { t } = useTranslation("common");

  return (
    <IndexMarketingCampaignBadge
      icon="people"
      iconColor="background.primary"
      label={t("advertisement_invite-only_status")}
    />
  );
};

export default IndexMarketingCampaignInviteOnlyBadge;
