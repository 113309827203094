import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import { Vendor } from "~/components/landings/constants";
import { VENDOR_TO_ADVERTISEMENT_MAP } from "~/components/landings/vendorToAdvertisementMap";
import { ColorsFromPalette } from "~/theme/colorsFromPalette";
import typography from "~/theme/typography";
import { backgroundFromPaletteMixin } from "~/utils/style";

import IndexMarketingCampaignStatisticsItem, {
  IndexMarketingCampaignStatisticsItemProps
} from "../IndexMarketingCampaignStatisticsItem";

type Props = {
  type: ReactNode;
  title: ReactNode;
  description: ReactNode;
  children: ReactNode;
  logo: ReactNode;
  logoBackground: ColorsFromPalette;
  background: ColorsFromPalette;
  image: string;
  topStatisticsItem: IndexMarketingCampaignStatisticsItemProps;
  bottomStatisticsItem: IndexMarketingCampaignStatisticsItemProps;
  pictureOnRight: boolean;
  className?: string;
};

const IndexMarketingCampaign = ({
  type,
  title,
  description,
  children,
  topStatisticsItem,
  bottomStatisticsItem,
  background,
  image,
  logo,
  logoBackground,
  pictureOnRight,
  className
}: Props): JSX.Element => {
  const advertisementId = VENDOR_TO_ADVERTISEMENT_MAP[Vendor.Cleo];

  if (!advertisementId) {
    throw new Error("Add postId for Cleo campaign");
  }

  return (
    <Wrapper background={background} className={className}>
      <IllustrationWrapper
        backgroundImage={image}
        pictureOnRight={pictureOnRight}
      >
        <LogoWrapper background={logoBackground}>{logo}</LogoWrapper>
      </IllustrationWrapper>
      <DetailsWrapper>
        <TextAndActionsWrapper>
          <Type>{type}</Type>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <ActionsWrapper>{children}</ActionsWrapper>
        </TextAndActionsWrapper>
        <StatisticsWrapper>
          <IndexMarketingCampaignStatisticsItem {...topStatisticsItem} />
          <IndexMarketingCampaignStatisticsItem {...bottomStatisticsItem} />
        </StatisticsWrapper>
      </DetailsWrapper>
    </Wrapper>
  );
};

export default IndexMarketingCampaign;

const Wrapper = styled.article`
  ${backgroundFromPaletteMixin}

  border-radius: 24px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row-reverse;
  }
`;

const DetailsWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    padding: ${({ theme }) => theme.spacing(6)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: ${({ theme }) => theme.spacing(7, 10)};
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    padding: ${({ theme }) => theme.spacing(8, 8, 8, 10)};
  }

  ${({ theme }) => theme.breakpoints.up("xxl")} {
    padding: ${({ theme }) => theme.spacing(8, 8, 8, 16)};
  }
`;

const TextAndActionsWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 456px;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    max-width: 514px;
  }

  ${({ theme }) => theme.breakpoints.up("xxl")} {
    max-width: 720px;
  }
`;

const StatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    flex-direction: row;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.up("xl")} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    margin-top: 0;
    max-width: 328px;
    min-width: 214px;
  }
`;

const Type = styled(Typography)`
  background: ${({ theme }) => theme.palette.transparent.inverse[4]};
  border-radius: 40px;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(3, 3)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    padding: ${({ theme }) => theme.spacing(3, 6)};
  }
`;
Type.defaultProps = {
  variant: "buttonTertiary"
};

const Title = styled(Typography)`
  ${typography.h4}
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up("xxl")} {
    ${typography.h3}
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

const Description = styled(Typography)`
  ${typography.paragraphTertiary}
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up("xxl")} {
    ${typography.paragraphSecondary}
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};
  justify-content: flex-start;
  margin-top: ${({ theme }) => theme.spacing(4)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-wrap: nowrap;
    margin-top: ${({ theme }) => theme.spacing(6)};
  }
`;

const IllustrationWrapper = styled("div", {
  shouldForwardProp: prop =>
    !["backgroundImage", "pictureOnRight"].includes(prop)
})<{
  backgroundImage: string;
  pictureOnRight: boolean;
}>`
  aspect-ratio: 1;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  position: relative;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex: 1;
    max-width: 408px;
    order: ${({ pictureOnRight }) => (pictureOnRight ? 0 : 1)};
  }
`;

const LogoWrapper = styled.div`
  ${backgroundFromPaletteMixin}

  align-items: center;
  border-radius: 20px;
  bottom: ${({ theme }) => theme.spacing(4)};
  display: flex;
  height: 108px;
  justify-content: center;
  left: ${({ theme }) => theme.spacing(4)};
  position: absolute;
  width: 208px;

  ${({ theme }) => theme.breakpoints.up("xs")} {
    bottom: ${({ theme }) => theme.spacing(6)};
    left: ${({ theme }) => theme.spacing(6)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    bottom: ${({ theme }) => theme.spacing(4)};
    left: ${({ theme }) => theme.spacing(4)};
  }
`;
