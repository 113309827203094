import useTranslation from "next-translate/useTranslation";

import Image from "~/public/assets/images/campaigns/GoogleCloud/Background.jpg";
import Logo from "~/public/assets/images/campaigns/GoogleCloud/Logo.svg";

import {
  CompletedBadge,
  FullyClaimedBadge,
  OutlinedIndexMarketingCampaign
} from "./common/styled";

const IndexMarketingGoogleCloudCampaign = (): JSX.Element => {
  const { t } = useTranslation("index");

  return (
    <OutlinedIndexMarketingCampaign
      type={t("google-cloud_type")}
      title={t("google-cloud_title")}
      description={t("google-cloud_description")}
      background="background.octonary"
      logo={<Logo />}
      logoBackground="background.septenary"
      image={Image.src}
      topStatisticsItem={{
        icon: "people",
        background: "background.primary",
        label: t("event-details_label"),
        value: t("google-cloud_individual-reward_title")
      }}
      bottomStatisticsItem={{
        icon: "reward",
        background: "background.primary",
        label: t("total-reward_label"),
        value: t("google-cloud_total-reward_title")
      }}
      pictureOnRight={false}
    >
      <CompletedBadge />
      <FullyClaimedBadge />
    </OutlinedIndexMarketingCampaign>
  );
};

export default IndexMarketingGoogleCloudCampaign;
