import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import Icon from "~/components/core/Icon";
import { IconColor, IconVariant } from "~/components/core/Icon/declarations";

type Props = {
  label: ReactNode;
  icon: IconVariant;
  iconColor: IconColor;
  className?: string;
};

const IndexMarketingCampaignBadge = ({
  label,
  icon,
  iconColor,
  className
}: Props): JSX.Element => (
  <Wrapper className={className}>
    <Icon variant={icon} color={iconColor} size={20} />
    <Label>{label}</Label>
  </Wrapper>
);

export default IndexMarketingCampaignBadge;

const Wrapper = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.transparent.inverse[4]};
  border-radius: 40px;
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(3, 2)};

  ${({ theme }) => theme.breakpoints.up("xs")} {
    padding: ${({ theme }) => theme.spacing(3, 4)};
  }
`;

const Label = styled(Typography)``;
Label.defaultProps = {
  variant: "buttonTertiary"
};
