import { CheckAttendanceBeforeClaimDialogPayload } from "~/components/advertisement/claim/CheckAttendanceBeforeClaimDialog/declarations";
import { HasNeedToClaimCheck } from "~/components/advertisement/claim/useClaimFlow/useClaimFlow";
import useUnmountableDialog, {
  UseDialogHookResult
} from "~/hooks/useUnmountableDialog";

type UseCheckAttendanceBeforeClaimResult = {
  claimAlert: UseDialogHookResult<CheckAttendanceBeforeClaimDialogPayload>;
  hasNeedToClaimCheck: HasNeedToClaimCheck;
};

const useCheckAttendanceBeforeClaim =
  (): UseCheckAttendanceBeforeClaimResult => {
    const claimAlert =
      useUnmountableDialog<CheckAttendanceBeforeClaimDialogPayload>();

    const hasNeedToClaimCheck: HasNeedToClaimCheck = (): Promise<boolean> =>
      new Promise(resolve => {
        claimAlert.openDialog({
          onResolve: resolve
        });
      });

    return {
      claimAlert,
      hasNeedToClaimCheck
    };
  };

export default useCheckAttendanceBeforeClaim;
